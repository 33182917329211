<template>
	<!-- begin error -->
	<div class="error">
		<div class="error-code m-b-10">404</div>
		<div class="error-content">
			<div class="error-message">Kami tidak menemukannya...</div>
			<div class="error-desc m-b-30">
				Halaman yang ingin anda akses tidak tersedia. <br />
			</div>
			<div>
				<router-link to="/dashboard" class="btn btn-success p-l-20 p-r-20">Kembali ke halaman Utama</router-link>
			</div>
		</div>
	</div>
	<!-- end error -->
</template>

<script>
import PageOptions from '@/config/PageOptions.vue'

export default {
	created() {
		PageOptions.pageEmpty = true;
	},
	beforeRouteLeave (to, from, next) {
		PageOptions.pageEmpty = false;
		next();
	}
}
</script>